import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import cn from 'clsx'
import FloatingComponent from './FloatingComponent'
import { landingMobileBreakpoint } from '@pages/index'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    minWidth: 240,
  },
  image: {
    width: '100%',
    maxWidth: 348,
    borderRadius: 8,
    zIndex: 0,
    boxShadow:
      '0px 10.69px 14.58px -6.8px #0000000F, 0px 23.33px 36.94px 2.92px #0000000A, 0px 8.75px 44.72px 7.78px #0000000A',
  },
  user: {
    width: 62,
    height: 62,
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      width: 40,
      height: 40,
    },
  },
  bottomRight: {
    position: 'absolute',
    right: -30,
    bottom: 20,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      right: -30,
      bottom: 10,
    },
  },
  bottomRightMessage: {
    position: 'absolute',
    right: -90,
    bottom: -20,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      right: -30,
      bottom: 10,
    },
  },
  topLeft: {
    position: 'absolute',
    top: -40,
    left: 40,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      top: -20,
      left: 20,
    },
  },
  topLeftMessage: {
    position: 'absolute',
    top: 10,
    left: -130,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      top: -20,
      left: 20,
    },
  },
  bottomLeft: {
    position: 'absolute',
    bottom: -40,
    left: 0,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      bottom: -20,
      left: 0,
    },
  },
  topRight: {
    position: 'absolute',
    top: -40,
    right: -60,
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      top: -20,
      right: -20,
    },
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    position: 'relative',
    padding: theme.spacing(1),
    background: 'white',
    borderRadius: 8,
    zIndex: 100,
    width: 262,
    boxShadow:
      '0px 8.75px 44.72px 7.78px #0000000A, 0px 23.33px 36.94px 2.92px #0000000A, 0px 10.69px 14.58px -6.8px #0000000F',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      padding: 6,
      gap: theme.spacing(1),
    },
  },
  avatar: {
    width: 42,
    height: 42,
    '& img': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      width: 29,
      height: 29,
    },
  },
  messageText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  messageTitle: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '1rem',
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '0.725rem',
      lineHeight: '0.6875rem',
    },
  },
  messageCaption: {
    [theme.breakpoints.down(landingMobileBreakpoint)]: {
      fontSize: '0.5375rem',
      lineHeight: '0.5rem',
    },
  },
}))

const HomeFloatingImage = ({ className, variant = 1 }) => {
  const classes = useStyles()


  const imageUrl = () => {
    switch (variant) {
    case 1:
      return '/images/home/home_visual_1.webp'
    case 2:
      return '/images/home/home_visual_2.webp'
    case 3:
      return '/images/home/home_visual_3.webp'
    default:
      return '/images/home/home_visual_3.webp'
    }
  }

  const messagePosition = () => {
    switch (variant) {
      case 1:
        return classes.topRight
      case 2:
        return classes.topLeftMessage
      case 3:
        return classes.bottomRightMessage
      default:
        return
    }
  }

  const userPosition = () => {
    switch (variant) {
      case 1:
        return classes.bottomRight
      case 2:
        return classes.bottomLeft
      case 3:
        return classes.topLeft
      default:
        return
    }
  }

  const mainText = () => {
    switch (variant) {
    case 1:
      return 'Lia is going too 🔥'
    case 2:
      return 'Tickets are live 🎟️'
    case 3:
      return 'You’re invited 💌'
    default:
      return
    }
  }

  const subText = () => {
    switch (variant) {
    case 1:
      return 'They have also joined Museum Night.'
    case 2:
      return 'Studio 22 added tickets to their event. Get yours now.'
    case 3:
      return 'Pao invites you to Birthday BASH.'
    default:
      return
    }
  }

  const userWithIcon = () => {
    switch (variant) {
    case 1:
      return '/images/home/userWithIcon.webp'
    case 2:
      return '/images/home/userWithIcon3.webp'
    case 3:
      return '/images/home/userWithIcon2.webp'
    default:
      return
    }
  }

  const messageUser = () => {
    switch (variant) {
    case 1:
      return '/images/home/home_lia.webp'
    case 2:
      return '/images/home/home_studio_22.webp'
    case 3:
      return '/images/home/home_pao.webp'
    default:
      return
    }
  }

  return (
    <div className={cn(classes.root, className)}>
      <img className={classes.image} src={imageUrl()} alt='image' />

      <FloatingComponent
        wrapperClassName={userPosition()}
        className={cn(classes.user)}
        floatingEnabled={false}
      >
        <img
          className={cn(classes.floatingY)}
          src={userWithIcon()}
          alt='user'
        />
      </FloatingComponent>

      <FloatingComponent
        floatingEnabled={false}
        wrapperClassName={messagePosition()}
        className={cn(classes.message)}
      >
        <img
          className={cn(classes.avatar)}
          src={messageUser()}
          alt='user'
        />

        <div className={classes.messageText}>
          <Typography className={classes.messageTitle} variant='h6'>
            {mainText()}
          </Typography>

          <Typography
            className={classes.messageCaption}
            variant='caption'
          >
            {subText()}
          </Typography>
        </div>
      </FloatingComponent>
    </div>
  )
}

export default HomeFloatingImage
