import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'clsx'

const useStyles = makeStyles((theme) => ({
  '@keyframes floatingX': {
    '0%': {
      transform: 'translateX(-5%)',
    },
    '100%': {
      transform: 'translateX(5%)',
    },
  },
  '@keyframes floatingY': {
    '0%': {
      transform: 'translateY(-10%)',
    },
    '100%': {
      transform: 'translateY(10%)',
    },
  },
  floatingX1: {
    animation: '$floatingX 7.2s ease-in-out infinite alternate-reverse',
  },
  floatingY1: {
    animation: '$floatingY 4.8s ease-in-out infinite alternate-reverse',
  },
  floatingX2: {
    animation: '$floatingX 5.2s ease-in-out infinite alternate-reverse',
  },
  floatingY2: {
    animation: '$floatingY 7.8s ease-in-out infinite alternate-reverse',
  },
  floatingX3: {
    animation: '$floatingX 9.2s ease-in-out infinite alternate-reverse',
  },
  floatingY3: {
    animation: '$floatingY 6.8s ease-in-out infinite alternate-reverse',
  },
}))

const FloatingComponent = ({ wrapperClassName, className, children, floatingEnabled = true }) => {
  const classes = useStyles()

  const floatingXClass = [classes.floatingX1, classes.floatingX2, classes.floatingX3]
  const floatingYClass = [classes.floatingY1, classes.floatingY2, classes.floatingY3]

  const [randomX, _] = useState(floatingXClass[Math.floor(Math.random() * floatingXClass.length)])
  const [randomY, _1] = useState(floatingYClass[Math.floor(Math.random() * floatingYClass.length)])

  return (
    <div className={cn(floatingEnabled && randomX, wrapperClassName)}>
      <div className={cn(floatingEnabled && randomY, className)}>
        {children}
      </div>
    </div>
  )
}

export default FloatingComponent
