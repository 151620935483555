import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Typography from '@material-ui/core/Typography'
import TextLogo from '../../svg/TextLogo'
import cn from 'clsx'
import { useMediaQuery } from '@material-ui/core'
import Logo from 'svg/logo/IconBoxed'
import { Add } from '@material-ui/icons'

const useHeaderStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '1120px',
    gap: theme.spacing(2),
    width: '100%',
    alignItems: 'center',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(8.5),
    padding: '33px 0px',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(7),
    },
    '@media not all and (min-width:500px)': {
      justifyContent: 'center',
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
  download: {
    color: theme.palette.primary.main,
  },
  currentPage: {
    marginRight: 'auto',
  },
  tab: {
    color: theme.palette.text.primary,
    position: 'relative',
  },
  smallerOnMobile: {
    '@media not all and (min-width:500px)': {
      fontSize: '1rem',
    },
  },
  wordLogo: {
    color: theme.palette.gray[800],
    width: '56.54px',
    height: '14px',
  },
  selectedLine: {
    position: 'absolute',
    width: '100%',
    height: '1px',
    background: theme.palette.gray[900],
    bottom: '-4px',
  },
  hideOnMobile: {
    '@media not all and (min-width:420px)': {
      display: 'none',
    },
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '10.5px',
    marginRight: 'auto',
  },
  iconLogo: {
    width: 13.48,
    height: 14,
    color: theme.palette.gray[800],
  },
  signIn: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  createEvent: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    color: theme.palette.secondary[800],
    cursor: 'pointer',
  },
  addIcon: {
    width: 20,
    height: 20,
  },
}))

export const HomeHeader = ({}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(500))
  const isVerySmall = useMediaQuery(theme.breakpoints.down(370))
  const classes = useHeaderStyles()
  const { t } = useTranslation('common')
  const router = useRouter()
  const isPages = router.pathname === '/organisers'
  const isAbout = router.pathname === '/about'

  return (
    <div className={classes.flex}>
      <div className={classes.root}>
        <Link href='/' passHref prefetch={false} className={classes.logoContainer}>

          <Logo className={classes.iconLogo} />
          {!isSmall && (
            <TextLogo
              height={14}
              width={56}
              className={cn(classes.currentPage, classes.wordLogo)}
            />
          )}

        </Link>

        <Link href='/create' passHref prefetch={false} legacyBehavior>
          <div className={classes.createEvent}>
            <Add className={classes.addIcon} />
            <Typography
              className={cn(
                classes.tab,
                classes.smallerOnMobile,
                classes.createEvent,
              )}
              variant={isSmall ? 'body2' : 'body1'}
            >
              <a>{isVerySmall ? 'Create' : 'Create event'}</a>
            </Typography>
          </div>
        </Link>

        <Typography
          className={cn(classes.tab, classes.smallerOnMobile)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          {isPages && <div className={classes.selectedLine} />}
          <Link href='/organisers' passHref prefetch={false}>
            Organisers
          </Link>
        </Typography>

        <Typography
          className={cn(classes.tab, classes.smallerOnMobile, classes.signIn)}
          variant={isSmall ? 'body2' : 'body1'}
        >
          <Link href='/signIn' passHref prefetch={false}>
            Sign in
          </Link>
        </Typography>
      </div>
    </div>
  );
}

export default HomeHeader
